<template>
  <section>
    <!-- <b-row> -->
    <b-card no-body>
      <b-card-body>
        <b-card-title>
          <b-row>
            <b-col>
              <div>Ranking : {{ jadwaladmindata?.paket?.name }}</div>
            </b-col></b-row
          >
        </b-card-title>
        <b-card-text class="blog-content-truncate">
          <!-- <b-row> -->

          <div>
            <b-table-simple
              outlined
              bordered
              hover
              small
              caption-top
              responsive
            >
              <b-thead head-variant="light">
                <b-tr>
                  <b-th rowspan="3">No</b-th>
                  <b-th rowspan="3">Ranking</b-th>
                  <b-th rowspan="3">Nama</b-th>
                  <b-th rowspan="3">Sekolah</b-th>
                  <b-th rowspan="3">Total Skor</b-th>
                  <b-th>Rasionalisasi</b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr v-for="(hasil, index) in rasio" :key="hasil.id">
                  <b-td>
                    {{ index + 1 }}
                  </b-td>
                  <b-td>
                    {{ hasil.ranking.ke }}
                  </b-td>
                  <b-td>
                    {{ hasil.name }}
                  </b-td>
                  <b-td>
                    {{
                      hasil.school_origin == null
                        ? "-"
                        : hasil.school_origin.nama_sekolah
                    }}
                  </b-td>
                  <b-td>
                    {{ hasil?.jumlah_nilai ? hasil?.jumlah_nilai : 0 }}
                  </b-td>
                  <b-td>
                    <feather-icon
                      @click="viewRasionalisasi(hasil)"
                      icon="EyeIcon"
                      title="Hasil Rasionalisai"
                      size="24"
                      class="cursor-pointer"
                    ></feather-icon>
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </div>
        </b-card-text>
      </b-card-body>
    </b-card>
  </section>
</template>

<script>
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import {
  BTableLite,
  BMedia,
  BImg,
  BRow,
  BCol,
  BTable,
  BTableSimple,
  BThead,
  BTr,
  BTh,
  BTd,
  BTbody,
  BTfoot,
  BCard,
  BCardText,
  BCardTitle,
  BCardBody,
  BPagination,
  BProgress,
  BBadge,
  BButton,
  BModal,
  VBModal,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BInputGroup,
  BInputGroupAppend,
  BFormRadioGroup,
  BFormTextarea,
  VBTooltip,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  // props: ["jadwaladmindata"],
  components: {
    BTableLite,
    BMedia,
    BImg,
    AppCollapse,
    AppCollapseItem,
    BDropdown,
    BDropdownItem,
    BRow,
    BCol,
    BCard,
    BCardText,
    BCardTitle,
    BCardBody,
    BTable,
    BPagination,
    BProgress,
    BBadge,
    BButton,
    BModal,
    VBModal,
    BForm,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BInputGroup,
    BInputGroupAppend,
    BFormRadioGroup,
    BFormTextarea,
    VBTooltip,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTd,
    BTbody,
    BTfoot,
    ToastificationContent,
  },
  directives: {
    "b-modal": VBModal,
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      ranking: {},
      jadwaladmindata: {},
      rasio: [],
      mapels: [],
      optUjian: [],
      total: {},
      id: null,
      activeAction: null,
      label: "Simpan",
      //Data
      paketSubtestData: [],
      // jadwaladmindata: {
      //   name: null,
      // },
      form: {
        id: null,
        bobot: null,
        paket_id: null,
      },
      formSub: {
        id: null,
        // title: null,
        ujian_id: null,
        subcat_id: null,
        // timer: null,
        // sum_soal: null,
      },
      paketSubtestDataParent: [],
      paketSubtestDataItems: [],
      //Modal
      titleModal: null,
      Modal: false,
      ModalSub: false,
      //Table
      fields: [
        { key: "show_details", label: "#" },
        { key: "index", label: "No" },
        { key: "title", label: "Subtest", sortable: true },
        { key: "action", label: "#" },
      ],
      fieldsItems: [
        // { key: "index", label: "No" },
        { key: "title", label: "Subtest", sortable: true },
        // { key: "timer", label: "Waktu", sortable: true },
        // { key: "sum_soal", label: "Jumlah Soal", sortable: true },
        { key: "action", label: "#" },
      ],
      selected: [],
      // total: [],
      record: [],
      optFilter: [
        { id: "text", value: "Subtest" },
        // { id: "nip", value: "NIP" },
      ],
      perPage: 10,
      pageOptions: [10, 30, 50],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      // filter: "Subtest Paket",
      search: null,
      idd: null,
    };
  },
  watch: {},
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  methods: {
    viewRasionalisasi(hasil) {
      const userId = hasil.id || hasil.user_id;
      this.$store.commit("rasionalisasi/SET_RASIONALISASI_DATA", hasil);
      this.$router.push({
        name: "report-rasionalisasi",
        params: {
          jadwal_id: this.jadwaladmindata.id,
          siswa_id: userId,
        },
      });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    tutupModal() {
      this.Modal = false;
      this.ModalSub = false;
      this.resetForm();
      this.getRasioSMA();
    },
    resetForm() {
      this.form = {
        id: null,
        text: null,
        paket_id: null,
      };
      this.formSub = {
        id: null,
        title: null,
        paket_id: null,
        parent_id: null,
        timer: null,
        sum_soal: null,
      };
    },
    ModalShow() {
      this.id = null;
      this.activeAction = "tambah";
      this.titleModal = "Tambah Subtest Paket";
      this.Modal = true;
      // this.ModalEdit = false;
    },
    ModalUbah(item) {
      this.id = item.id;
      this.form = item;
      this.activeAction = "ubah";
      this.titleModal = "Ubah Subtest Paket";
      this.Modal = true;
      // this.ModalEdit = true;
    },
    ModalHapus(data) {
      this.$swal({
        title: "Anda Yakin?",
        text: "Data tidak bisa dikembalikan!",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "Batal",
        confirmButtonText: "Ya, Hapus!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          const item = {
            id: data.id,
            fungsi: 1,
          };
          // data.fungsi = 1; //soft delete
          this.$store
            .dispatch("paketTryout/CUDSubtestPaket", [item])
            .then(() => {
              this.getRasioSMA();
              this.displaySuccess({
                text: "Data berhasil dihapus",
              });
            })
            .catch((e) => {
              this.displayError(e);
              return false;
            });
          this.$swal({
            icon: "success",
            title: "Terhapus!",
            text: "Data berhasil dihapus.",
            // timer: 1000,
            allowOutsideClick: true,
            customClass: {
              confirmButton: "btn btn-success",
            },
            buttonsStyling: false,
          });
        } else if (result.dismiss === "cancel") {
          this.$swal({
            title: "Batal",
            text: "Data aman, kamu batal menghapus data :)",
            icon: "error",
            // timer: 1000,
            allowOutsideClick: true,
            customClass: {
              confirmButton: "btn btn-success",
            },
            buttonsStyling: false,
          });
        }
      });
    },
    pesanGagal() {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Notifikasi",
            icon: "AlertCircleIcon",
            text: "Gagal menyimpan data, harap cek kembali data yang diisi",
            variant: "warning",
          },
        },
        {
          position: "bottom-right",
        }
      );
    },
    Tambah() {
      // if (this.form.bobot == null || this.form.bobot == "") {
      //   this.pesanGagal();
      //   return false;
      // }
      let payload = {
        bobot: this.form.bobot,
        paket_id: this.$route.params.id,
      };

      if (this.id) {
        payload.id = this.id;
      }

      this.$store
        .dispatch("rasionalisasi/CUDsma", payload)
        .then(() => {
          if (this.activeAction == "tambah") {
            this.id = null;
          }
          // this.ModalEdit = false;
          this.getRasioSMA();
          this.displaySuccess({
            text: "Rasionalisasi Paket berhasil di" + this.activeAction,
          });
          this.resetForm();
          this.Modal = false;
        })
        .catch((e) => {
          this.displayError(e);
          return false;
        });
    },
    async getRasioSMA() {
      const payload = {
        // sekolah_id: localStorage.getItem("sekolah"),
        // filter: this.filter !== null ? this.filter.id : "text",
        // search: this.search,
        // start: (this.currentPage - 1) * this.itemsPerPage,
        // length: this.itemsPerPage,
        jadwal_id: this.$route.params.id,
        paket_id: this.jadwaladmindata.paket.id,
      };
      this.$store
        .dispatch("rasionalisasi/index", payload)
        .then((response) => {
          let mapels = response.data.mapels;
          let total = response.data;
          let paketSubtestData = response.data.data;
          this.mapels = mapels;
          this.total = total;
          this.rasio = paketSubtestData;
        })
        .catch((error) => {
          this.$root.$emit("errorData", error);
        });
    },

    async getRasioSMAParent() {
      const payload = {
        // sekolah_id: localStorage.getItem("sekolah"),
        // filter: this.filter !== null ? this.filter.id : "text",
        // search: this.search,
        // start: (this.currentPage - 1) * this.itemsPerPage,
        // length: this.itemsPerPage,
        paket_id: this.$route.params.id,
        ujian_id: 0,
      };
      this.$store
        .dispatch("adminujian/subtesPaket", payload)
        .then((response) => {
          let paketSubtestDataParent = response.data.data;
          paketSubtestDataParent.map((item) => {
            item.value = item.id;
            item.text = item.name;
          });
          this.paketSubtestDataParent = paketSubtestDataParent;
        })
        .catch((error) => {
          this.$root.$emit("errorData", error);
        });
    },
    async getDataById() {
      this.$store
        .dispatch("jadwaladmin/indexId", this.$route.params.id)
        .then((response) => {
          let jadwaladmindata = response.data;
          this.jadwaladmindata = jadwaladmindata;
          this.$store.commit(
            "rasionalisasi/SET_CURRENT_JADWAL",
            jadwaladmindata
          );
          this.$store.commit(
            "rasionalisasi/SET_CURRENT_PAKET",
            jadwaladmindata?.paket
          );
          this.getRasioSMA();
          this.getRanking();
        })
        .catch((error) => {
          this.$root.$emit("errorData", error);
        });
    },
    async getRanking() {
      const payload = {
        // sekolah_id: localStorage.getItem("sekolah"),
        // filter: this.filter !== null ? this.filter.id : "text",
        // search: this.search,
        // start: (this.currentPage - 1) * this.itemsPerPage,
        // length: this.itemsPerPage,
        jadwal_id: this.$route.params.id,
        paket_id: this.jadwaladmindata.paket.id,
      };
      this.$store
        .dispatch("ranking/paket", payload)
        .then((response) => {
          let ranking = response.data;
          this.ranking = ranking;
        })
        .catch((error) => {
          this.$root.$emit("errorData", error);
        });
    },
    //SUB

    ModalShowSub(paket) {
      this.getRasioSMAParent();
      this.getDataUjian();
      this.id = null;
      this.activeAction = "tambah";
      this.titleModal = "Tambah Bagian Subtest Paket";
      this.ModalSub = true;
      this.formSub.subcat_id = paket.id;
      // this.ModalEdit = false;
    },
    ModalUbahSub(data) {
      this.getRasioSMAParent();
      this.getDataUjian();
      this.id = data.id;
      this.formSub = data;
      this.activeAction = "ubah";
      this.titleModal = "Ubah Bagian Subtest Paket";
      this.ModalSub = true;
      // this.ModalEdit = true;
    },
    ModalHapusSub(dataSub) {
      this.$swal({
        title: "Anda Yakin?",
        text: "Data tidak bisa dikembalikan!",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "Batal",
        confirmButtonText: "Ya, Hapus!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          const sub = {
            id: dataSub,
            fungsi: 1, //soft delete
          };
          this.$store
            .dispatch("paketTryout/CUDSubtestPaket", [sub])
            .then(() => {
              this.getRasioSMA();
              this.displaySuccess({
                text: "Data berhasil dihapus",
              });
            })
            .catch((e) => {
              this.displayError(e);
              return false;
            });
          this.$swal({
            icon: "success",
            title: "Terhapus!",
            text: "Data berhasil dihapus.",
            // timer: 1000,
            allowOutsideClick: true,
            customClass: {
              confirmButton: "btn btn-success",
            },
            buttonsStyling: false,
          });
        } else if (result.dismiss === "cancel") {
          this.$swal({
            title: "Batal",
            text: "Data aman, kamu batal menghapus data :)",
            icon: "error",
            // timer: 1000,
            allowOutsideClick: true,
            customClass: {
              confirmButton: "btn btn-success",
            },
            buttonsStyling: false,
          });
        }
      });
    },
    TambahSub() {
      if (this.formSub.ujian_id == null || this.formSub.ujian_id == "") {
        this.pesanGagal();
        return false;
      }
      let payload = {
        paket_id: this.$route.params.id,
        subcat_id: this.formSub.subcat_id,
        ujian_id: this.formSub.ujian_id,
      };

      if (this.id) {
        payload.id = this.id;
      }

      this.$store
        .dispatch("adminujian/storeSubtestPaket", [payload])
        .then(() => {
          if (this.activeAction == "tambah") {
            this.id = null;
          }
          // this.ModalEdit = false;
          this.getRasioSMA();
          this.displaySuccess({
            text: "Subtest Paket berhasil di" + this.activeAction,
          });
          this.resetForm();
          this.ModalSub = false;
        })
        .catch((e) => {
          this.displayError(e);
          return false;
        });
    },
    async getDataUjian() {
      this.loading = true;
      const payload = {
        // sekolah_id: localStorage.getItem("sekolah"),
        // filter: this.filter !== null ? this.filter.id : "name",
        // search: this.search,
        // start: (this.currentPage - 1) * this.itemsPerPage,
        // length: this.itemsPerPage,
        category_paket_id: this.jadwaladmindata.category.id,
        mapel_id: this.filterMapel != null ? this.filterMapel.id : null,
        type_ujian: this.filterTipe != null ? this.filterTipe.id : null,
        user_id: this.user.id,
      };
      this.$store
        .dispatch("adminujian/index", payload)
        .then((response) => {
          this.loading = false;
          let ujiandata = response.data.data;
          this.ujiandata = ujiandata;
          ujiandata.map((item) => {
            item.value = item.id;
            item.text = item.name;
          });
          this.optUjian = ujiandata;

          // this.totalRows = this.ujiandata.length;
        })
        .catch((error) => {
          this.loading = false;
          this.$root.$emit("errorData", error);
        });
    },
  },
  mounted() {
    this.getDataById();
  },
};
</script>

<style></style>
